<template>
  <div>
    <v-card
      flat
      :max-width="maxWidth"
      :min-width="minWidth"
      :max-height="maxHeight"
      :min-height="minHeight"
    >
      <v-card-text v-if="currentOrder">
        <div
          style="d-flex justify-center aling-center font-size: 15px; font-weight: bold"
        >
          Riepilogo ordine collegato
          <v-icon @click="openDialogOrderUpdate = true">mdi-arrow-up-right</v-icon>
        </div>
        <v-divider></v-divider>
        <v-list class="transparent">
          <v-list-item>
            <v-icon color="success" class="mr-3">mdi-package-up</v-icon>
            <v-list-item-title class="">
              {{ currentOrder.code }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item class="">
            <v-icon color="" class="mr-3">mdi-calendar</v-icon>
            <v-list-item-subtitle class="">
              <DateTimeFormattor
                :with-time="true"
                v-model="currentOrder.startDate"
                not-specified-text="Non specificato"
              ></DateTimeFormattor>
            </v-list-item-subtitle>
          </v-list-item>
          <v-list-item>
            <v-icon color="success" class="mr-3">mdi-warehouse</v-icon>
            <v-list-item-title class="">
              {{ currentOrder.businessName }}
            </v-list-item-title>
          </v-list-item>
          <v-divider></v-divider>
          <!-- <v-list-item-subtitle class="ml-5">
            {{ currentOrder.description }}
            <v-icon color="" class="mr-3">mdi-arrow-down</v-icon>
            <v-divider></v-divider>
            <div
              :class="{
                'overflow-y': 'scroll',
                'min-height': '50px',
                'max-height': '50px',
              }"
            >
              <v-list-item-subtitle
                v-for="oi in currentOrder.orderItems"
                :key="oi.id"
                class="ml-5"
              >
                N° {{ oi.qtyToOrder }} - {{ oi.item.code }}
                <v-list-item-subtitle class="ml-5">
                  € {{ oi.price }} x {{ Number(oi.qtyDelivered) }} pz
                </v-list-item-subtitle>
              </v-list-item-subtitle>
              <v-divider></v-divider>
            </div>
          </v-list-item-subtitle> -->
        </v-list>
      </v-card-text>
      <div v-else>loading...</div>
    </v-card>
    
    <OrderUpdateDialog
      v-if="openDialogOrderUpdate"
      :order-id="currentOrderId.toString()"
      :open-dialog="openDialogOrderUpdate = true"
      :showRealQuantities="true"
      @close="openDialogOrderUpdate= false"
      variant="invoice"
    >
    </OrderUpdateDialog>
  </div>
</template>

<script>
import OrderUpdateDialog from "@/components/areas/warehouse/orders/new/OrderUpdateDialog.vue";

import orderService from "@/services/warehouse/orders.service";
import DateTimeFormattor from "@/components/common/DateTimeFormattor";
export default {
  components: {
    DateTimeFormattor,
    OrderUpdateDialog
  },
  props: {
    currentOrderId: {
      type: Number,
      default: 0,
    },
    maxWidth: {
      type: String,
      default: "12vw",
    },
    maxHeight: {
      type: String,
      default: "40vh",
    },
    minWidth: {
      type: String,
      default: "12vw",
    },
    minHeight: {
      type: String,
      default: "40vh",
    },
  },
  data() {
    return {
      currentOrder: undefined,
      openDialogOrderUpdate: false,
    };
  },
  mounted() {
    this.fetchOrder(this.currentOrderId);
  },
  methods: {
    fetchOrder(id) {
      orderService
        .get(id)
        .then((order) => {
          this.currentOrder = {
            code: order.code,
            description: order.description,
            businessName: order.supplier.businessName,
            orderItems: order.orderItems,
            startDate: order.startDate,
            endDate: order.endDate,
          };
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  watch: {
    currentOrderId(newVal) {
      this.fetchOrder(newVal);
    },
  },
};
</script>

<style>
</style>