<template>
  <StandardDialog
    dialog-width="40vw"
    dialog-height="calc(560-1)"
    persistent
    v-model="localOpenDialog"
    title="Dettaglio Scontrino"
  >

    <ListItemComponent
      class="ma-2"
      title="Prodotti Ordinati"
      color=" light-green lighten-5"
      :items="localBillItems"
      :item-description="(item) => item.item.description"
      :item-price="(item) => item.item.priceSelling"
      :item-quantity="(item) => item.quantity"
    >
    </ListItemComponent>
    <ListItemComponent
      class="ma-2"
      title="Service Effettuati"
      color=" light-green lighten-5"
      :items="localBillServices"
      :item-description="(service) => service.service.description"
      :item-price="(service) => service.service.price"
      :tem-quantity="(service) => service.quantity"
    >
    </ListItemComponent>

    <template v-slot:footer>
      <v-spacer></v-spacer>
      <v-btn text color="error" @click="$emit('close')">Chiudi</v-btn>
    </template>
  </StandardDialog>
</template>

<script>
import ListItemComponent from "@/components/common/ListItemComponent.vue";

import StandardDialog from "@/components/common/StandardDialog.vue";

export default {
  components: {
    StandardDialog,
    ListItemComponent,
  },
  props: {
    bill: {},
    openDialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localBillItems: this.bill.billItems,
      localBillServices: this.bill.billServices,
      localOpenDialog: this.openDialog,
    };
  },
};
</script>

<style>
</style>