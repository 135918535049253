<template>
  <v-row>
    <v-col cols="4" class="mt-3">
      <div class="d-flex justify-center align-center border-default">
        <div class="justify-center align-center">
          <div class="d-flex justify-center align-center text-h3">
            {{ invoice.internalCode }}
          </div>
        </div>
      </div>
      <v-timeline dense clipped>
        <v-timeline-item fill-dot class="white--text mb-4" color="grey" large>
          <template v-slot:icon>
            <span style="transform: scale(2.5)">€</span>
          </template>
          <v-text-field
            class="border-default"
            v-if="!!invoice && invoice.status !== 'paid'"
            v-model="amountTransaction"
            type="number"
            hide-details
            flat
            label="Inserisci importo..."
            solo
            @keydown.enter="addPaymentTransaction"
            :rules="[amountCheck]"
          >
          </v-text-field>
          <v-btn
            v-if="!!invoice && invoice.status !== 'paid'"
            class="mx-0"
            depressed
            rounded
            @click="addPaymentTransaction"
          >
            Paga
          </v-btn>
        </v-timeline-item>
        <v-timeline-item class="mb-6">
          Totale da Pagare
          <div class="mb-4 d-flex justify-center align-center border-default">
            <div class="justify-center align-center">
              <div class="d-flex justify-center align-center text-h3">
                € {{ invoice.amount }}
              </div>
            </div>
          </div>

          Pagato
          <div class="mb-4 d-flex justify-center align-center border-default">
            <div class="justify-center align-center">
              <div class="d-flex justify-center align-center text-h3">
                € {{ partialAmountPaid }}
              </div>
            </div>
          </div>

          Rimanente
          <div class="mb-4 d-flex justify-center align-center border-default">
            <div class="justify-center align-center">
              <div class="d-flex justify-center align-center text-h3">
                € {{ partialAmountToPay }}
              </div>
            </div>
          </div>
        </v-timeline-item>
      </v-timeline>
    </v-col>
    <v-col cols="4" class="mt-15">
      Lista transazioni
      <v-timeline dense clipped>
        <v-slide-x-transition group>
          <v-timeline-item
            v-for="(paymentTransaction, i) in timeline"
            :key="paymentTransaction.id"
            color="#73ad21"
            medium
          >
            <template v-slot:icon>
              <span>{{ Number(i) + 1 }}</span>
            </template>
            <div class="border-chip over-transaction">
              <p class="d-flex justify-center alig-center">
                {{
                  "Rata  " +
                  (Number(i) + 1) +
                  ": € " +
                  paymentTransaction.amount
                }}
              </p>
              <div class="d-flex justify-center alig-center">
                <DateTimeFormattor
                  :with-time="true"
                  :value="new Date(paymentTransaction.createdAt)"
                ></DateTimeFormattor>
              </div>
            </div>
          </v-timeline-item>
        </v-slide-x-transition>
      </v-timeline>
    </v-col>
    <v-col v-if="false" cols="4" class="mt-15">
      <PaymentTermsForm></PaymentTermsForm>
    </v-col>
  </v-row>
</template>

<script>
import Vue from "vue";
import invoiceService from "@/services/invoices/invoices.service.js";
import DateTimeFormattor from "@/components/common/DateTimeFormattor.vue";

import PaymentTermsForm from "@/components/areas/payments/invoices/components/PaymentTermsForm.vue";

export default {
  components: {
    PaymentTermsForm,
    DateTimeFormattor,
  },
  props: {
    bus: {
      type: Object,
      default: function () {
        return new Vue();
      },
    },
    invoice: {
      type: Object,
      default: () => {},
    },
    variant: {
      type: String,
      default: "new",
      validator: function (value) {
        if (!["new", "edit", "detail"].includes(value)) {
          return "invalid variant";
        } else {
          return true;
        }
      },
    },
  },
  data() {
    let localPaymentTransaction = [];
    if (!!this.invoice && !!this.invoice.invoicePaymentTransactions.length > 0)
      this.invoice.invoicePaymentTransactions.map((invoicePayTran) => {
        localPaymentTransaction.push({
          id: invoicePayTran.paymentTransactions[0].id,
          amount: invoicePayTran.paymentTransactions[0].amount,
          createdAt: invoicePayTran.paymentTransactions[0].createdAt,
        });
      });
    return {
      paymentTransactions: localPaymentTransaction,
      amountTransaction: null,
      amountCheck: (amount) => {
        if (this.invoice.amount - amount === 0) {
          this.$delegates.snackbar("Importo corretto");
          return false;
        } else {
          return false;
        }
      },
    };
  },
  computed: {
    timeline() {
      return this.paymentTransactions.slice();
    },
    partialAmountPaid() {
      let partialAmountPaid = 0;
      if (!!this.paymentTransactions.length > 0)
        this.paymentTransactions.map((pt) => {
          partialAmountPaid += Number(pt.amount);
        });
      return partialAmountPaid;
    },
    partialAmountToPay() {
      let partialAmountToPay = this.invoice.amount;
      if (!!this.paymentTransactions.length > 0)
        this.paymentTransactions.map((pt) => {
          partialAmountToPay -= Number(pt.amount);
        });
      return partialAmountToPay;
    },
  },
  mounted() {},
  methods: {
    pay() {
      this.invoice.status = "paid";
      invoiceService.update(this.invoice).then((result) => {
        this.bus.$emit("reload-invoice");
        this.$delegates.snackbar("Fattura pagata correttamente");
      });
    },
    addPaymentTransaction() {
      let paymentTransactiont = {
        amount: this.amountTransaction,
        date: new Date(),
        type: "invoice",
      };

      if (Number(this.amountTransaction) > Number(this.partialAmountToPay)) {
        this.$delegates.snackbar(
          "Inserire quantità minire di quella da pagare."
        );
      } else if (this.amountTransaction > 0) {
        invoiceService
          .addPaymentTransactionToInvoice(this.invoice, paymentTransactiont)
          .then((paymentTransaction) => {
            this.paymentTransactions.push({
              id: paymentTransaction.id,
              amount: paymentTransaction.amount,
              createdAt: paymentTransaction.createdAt,
            });
            if (this.partialAmountToPay <= 0) {
              this.pay();
            }
            this.amountTransaction = 0;
          });
      } else {
        this.$delegates.snackbar("Inserire quantità maggiore di 0.");
      }
    },
  },
};
</script>

<style>
.border-default {
  padding: 5px;
  border-radius: 25px;
  border: 1px solid #DCD8D8;
}
.border-transaction {
  padding: 5px;
  border-radius: 25px;
  border: 1px solid #DCD8D8;
}
.over-transaction:hover {
  border: 0px solid #73ad21;
  border-radius: 25px;
  transition: all 0.2s;
  transform: scale(1.2);
  background-color: #73ad21;
}
</style>