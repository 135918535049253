<template>
  <div>
    <PaymentTransactionList :invoice="invoice"></PaymentTransactionList>
    
  </div>
</template>

<script>
import PaymentTransactionList from "@/components/areas/payments/invoices/components/PaymentTransactionList.vue";
import Vue from "vue";

export default {
  name: "Timeline",
  components: {
    PaymentTransactionList,
  },
  props: {
    bus: {
      type: Object,
      default: function () {
        return new Vue();
      },
    },
    invoice: {
      type: Object,
      default: () => {},
    },
    variant: {
      type: String,
      default: "new",
      validator: function (value) {
        if (!["new", "edit", "detail"].includes(value)) {
          return "invalid variant";
        } else {
          return true;
        }
      },
    },
  },
  mounted() {
    var self = this;
    if(!self.invoice) this.bus.$emit("reload-invoice");
    // this.bus.$on("reload-invoice", self.invoicePaying);
  },
  data() {
    return {
    }
  },
  methods: {
    invoicePaying() {
      this.bus.$emit("reload-invoice");
    },
  },
};
</script>

<style>
</style>