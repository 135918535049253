<template>
  <div>
    <v-card
      flat
      :max-width="maxWidth"
      :min-width="minWidth"
      :max-height="maxHeight"
      :min-height="minHeight"
    >
      <v-card-text v-if="currentBill">
        <div
          style="d-flex justify-center aling-center font-size: 15px; font-weight: bold"
        >
          Riepilogo scontrino collegato
          <v-icon @click="openDetailBill = true">mdi-arrow-up-right</v-icon>
        </div>
        <v-divider></v-divider>
        <v-list class="transparent">
          <v-list-item>
            <v-icon color="success" class="mr-3">mdi-account</v-icon>
            <v-list-item-title class="">
              {{ currentBill.fullname }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item class="">
            <v-icon color="" class="mr-3">mdi-calendar</v-icon>
            <v-list-item-subtitle class="">
              <DateTimeFormattor
                :with-time="true"
                v-model="currentBill.startDate"
                not-specified-text="Non specificato"
              ></DateTimeFormattor>
            </v-list-item-subtitle>
          </v-list-item>
          <v-divider></v-divider>
        </v-list>
      </v-card-text>
      <div v-else>loading...</div>
    </v-card>

    <DetailBillClosed
      v-if="openDetailBill"
      :open-dialog="openDetailBill"
      :bill="currentBill"
      @close="openDetailBill = false"
    ></DetailBillClosed>
  </div>
</template>

<script>
import DetailBillClosed from "@/components/bar/daily-reservation/DetailBillClosed.vue";
import billService from "@/services/bills/bills.service";
import DateTimeFormattor from "@/components/common/DateTimeFormattor";
export default {
  components: {
    DateTimeFormattor,
    DetailBillClosed,
  },
  props: {
    currentBillId: {
      type: Number,
      default: 0,
    },
    maxWidth: {
      type: String,
      default: "12vw",
    },
    maxHeight: {
      type: String,
      default: "40vh",
    },
    minWidth: {
      type: String,
      default: "12vw",
    },
    minHeight: {
      type: String,
      default: "40vh",
    },
  },
  data() {
    return {
      currentBill: undefined,
      openDetailBill: false,
    };
  },
  mounted() {
    this.fetchBill(this.currentBillId);
  },
  methods: {
    fetchBill(id) {
      billService
        .get({ id: id })
        .then((bill) => {
          this.currentBill = {
            fullname:
              bill.billCustomers[0].customer.firstname +
              " " +
              bill.billCustomers[0].customer.lastname,
            billItems: bill.billItems,
            billServices: bill.billServices,
            startDate: bill.createdAt,
            endDate: bill.endDate,
          };
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  watch: {
    currentBillId(newVal) {
      this.fetchBill(newVal);
    },
  },
};
</script>

<style>
</style>