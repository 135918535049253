<template>
  <div>
    <v-col>
      <v-row class="pl-5 pb-10">
        <v-select
          :disabled="disabledForm"
          v-model="paymentTermId"
          :items="paymentTermsItems"
          item-value="id"
          item-text="description"
          return-object
          label="Modalità Di Pagamento"
          filled
          deletable-chips
          dense
          chips
          small-chips
          hide-details="auto"
          prepend-icon="mdi-contactless-payment"
          @change="updateObject('paymentTermId', $event)"
        ></v-select>
      </v-row>
      <v-row class="pl-5 pb-10">
        <v-select
          :disabled="disabledForm"
          v-model="paymentConditionId"
          :items="paymentConditionItems"
          item-value="id"
          item-text="description"
          return-object
          label="Condizione Di Pagamento"
          filled
          deletable-chips
          dense
          chips
          small-chips
          hide-details="auto"
          prepend-icon="mdi-contactless-payment"
          @change="updateObject('paymentConditionId', $event)"
        ></v-select>
      </v-row>
      <v-row class="pl-5 pb-10">
        <v-select
          :disabled="disabledForm"
          v-model="paymentTypeNatureExemptionId"
          :items="paymentTypeNatureExemptionItems"
          item-value="id"
          item-text="description"
          return-object
          label="Tipo Natura Esenzione IVA"
          filled
          deletable-chips
          dense
          chips
          small-chips
          hide-details="auto"
          prepend-icon="mdi-contactless-payment"
          @change="updateObject('paymentTypeNatureExemptionId', $event)"
        ></v-select>
      </v-row>
      <v-row class="pl-5 pb-10">
        <v-autocomplete
          :disabled="disabledForm"
          v-model="docPrint"
          :items="docPrintItems"
          :clearable="true"
          dense
          filled
          prepend-icon="mdi-cloud-print-outline"
          hide-details="auto"
          label="Stampa Nel Documento Commerciale"
          @change="updateObject('docPrint', $event)"
        ></v-autocomplete>
      </v-row>
    </v-col>
  </div>
</template>

<script>
import Vue from "vue";
import dataInvoiceService from "@/services/invoices/dataInvoices.service.js";
import invoiceForm from "@/services/invoices/invoice.form.js";
import paymentTermService from "@/services/paymentTerms/paymentTerms.service.js";
import paymentConditionService from "@/services/paymentTerms/paymentConditions.service.js";
import paymentTypeNatureExemptionService from "@/services/paymentTerms/paymentTypeNatureExemptions.service.js";
export default {
  data() {
    return {
      disabledForm: false,
      docPrintItems: [],
      docPrint: undefined,
      paymentTypeNatureExemptionId: undefined,
      paymentTypeNatureExemptionItems: [],
      paymentConditionId: undefined,
      paymentConditionItems: [],
      paymentTermId: undefined,
      paymentTermsItems: [],
    };
  },
  props: {
    bus: {
      type: Object,
      default: function () {
        return new Vue();
      },
    },
    variant: {
      type: String,
      default: "new",
      validator: function (value) {
        if (!["new", "edit", "detail"].includes(value)) {
          return "invalid variant";
        } else {
          return true;
        }
      },
    },
  },
  mounted() {
    if (this.variant === "detail") this.disabledForm = true;
    var self = this;
    invoiceForm.on("update", function (data) {
      self.handleObjectChanges(data.invoice);
    });
    this.fetchPaymentTerms();
  },
  methods: {
    fields() {
      return [
        "paymentConditionId",
        "paymentTermId",
        "paymentTypeNatureExemptionId",
      ];
    },
    handleObjectChanges(invoice) {
      const fields = this.fields();
      const newValKeys = Object.keys(invoice);

      for (let i = 0; i < fields.length; i++) {
        const field = fields[i];

        if (newValKeys.includes(field) && invoice[field] != this[field]) {
          this[field] = invoice[field];
        }
      }
    },
    fetchPaymentTerms() {
      new Promise(async (resolve, reject) => {
        let resultPaymentTermsItems = await paymentTermService.list();
        this.paymentTermsItems = [...resultPaymentTermsItems.rows];

        let resultPaymentConditionItems = await paymentConditionService.list();
        this.paymentConditionItems = [...resultPaymentConditionItems.rows];

        let resultPaymentTypeNatureExemptionItems =
          await paymentTypeNatureExemptionService.list();
        this.paymentTypeNatureExemptionItems = [
          ...resultPaymentTypeNatureExemptionItems.rows,
        ];

        this.docPrintItems = dataInvoiceService.listDocPrint();
        resolve();
      });
    },
    updateObject(key, value) {
      invoiceForm.updateField(key, value);
    },
  },
};
</script>

<style>
</style>